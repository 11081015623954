import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <>
            <Helmet>
                <title>Risk Disclosure Statement | Picco</title>
                <meta name="description" content="Privacy policy" />
                <meta property="og:title" content="Privacy policy | Picco" />
                <meta property="og:description" content="Privacy policy" />
            </Helmet>

            <div className="legal">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className="home-sections legal_container">
                            <div className="home-section" style={{ justifyContent: "flex-start" }}>
                                <div className="home-content02">
                                    <div className='page-title mb-4'> Risk Disclosure Statement </div>
                                    <span>
                            <strong>Last Updated: September 5, 2024</strong>
                        </span>
                        

                        <p>This Risk Disclosure Statement ("Statement") is provided by Picco Trading ("we," "us," or "our") to inform you ("you" or "user") of the risks associated with trading and investing through our website, <a href="www.piccotrading.com"> www.piccotrading.com </a>  (the "Site") and using our services. By accessing or using our Site and services, you acknowledge that you have read, understood, and agreed to this Statement in its entirety.
                        </p>
                            

                                    <h2>1. General Risk Acknowledgment</h2>
                                    <p>Trading in financial markets, including but not limited to stocks, options, futures, small cap, large cap, forex, and other financial instruments, involves significant risk and may not be suitable for all investors. The value of investments can go up or down, and you may lose some or all of your invested capital. You should carefully consider your financial objectives, risk tolerance, and investment experience before engaging in any trading activities.
                                    </p>

                                    <h3>2. No Guarantee of Profit or Loss Avoidance
                                    </h3>
                                    <p>Picco Trading does not guarantee any profit or protection against loss. The trading strategies, automated bots, signals, alerts, or any information provided through our services are based on data analysis, market research, and historical trends. However, past performance is not indicative of future results. You acknowledge that there is no assurance or warranty that any strategy, product, or service will result in positive trading outcomes.</p>

                                    <h3>3. Market Risks and Volatility</h3>
                                    <p>Markets can be highly volatile and unpredictable. Prices can change rapidly, and market conditions may be affected by factors beyond your control, such as economic events, political developments, regulatory changes, and unforeseen crises. You acknowledge that price movements can result in substantial losses, and you accept the inherent risk associated with such volatility.</p>


                                    <h3>4. Leverage and Margin Risks</h3>
                                    <p>Using leverage and margin in trading can magnify both gains and losses. While leverage can potentially enhance returns, it also exposes you to greater risk. You could lose more than your initial investment, and you may be required to deposit additional funds to cover losses. Picco Trading is not liable for any losses incurred due to the use of leverage or margin.</p>

                                    <h3>5. Automated Trading and Bot Risks
                                    </h3>
                                    <p>Picco Trading provides access to automated trading bots designed to execute trades based on predefined algorithms. These bots do not guarantee profits and may incur significant losses due to market fluctuations, system errors, internet connectivity issues, or other unforeseen events. You acknowledge that the use of automated trading bots involves risks, including but not limited to:</p>

                                    <ul>
                                        <li><strong>System Failures:</strong> Hardware or software failures, data corruption, connectivity interruptions, or third-party service provider outages.</li>
                                        <li><strong>Algorithmic Limitations:</strong> Algorithms may not perform as expected under all market conditions, and they may generate unintended results or be subject to inaccuracies.</li>
                                        <li><strong>Execution Delays:</strong> Market conditions, internet speed, and data transmission can cause delays in trade execution, which may result in unfavorable outcomes.</li>
                                    </ul>

                                    <p>You agree to monitor your account regularly and take full responsibility for the decisions made by any automated systems you choose to use.</p>


                                    <h3>6. Risks of Using Alerts and Signals</h3>
                                    <p>Trade alerts, signals, and recommendations provided by Picco Trading are for informational purposes only and should not be construed as investment advice. These alerts are generated based on market analysis, data trends, and expert opinions but do not guarantee any particular outcome. You are solely responsible for assessing the suitability of any alerts or signals and making your own trading decisions.</p>

                                    <h3>7. Third-Party Content and Service Risks
                                    </h3>
                                    <p>Our Site may include links to third-party websites, resources, or services, including data feeds, news, market analysis, or tools provided by third parties. Picco Trading does not endorse or control these third-party services and is not responsible for their accuracy, completeness, or reliability. Your use of any third-party services is at your own risk.</p>

                                    <h2>8. Regulatory and Legal Risks</h2>
                                    <p>Trading in financial markets may be subject to various local, national, or international laws and regulations. You are responsible for ensuring compliance with all applicable legal requirements in your jurisdiction. Picco Trading does not provide legal advice, and we make no representations regarding the legality of your trading activities.</p>

                        
                                    <h3>9. Taxation Risks</h3>
                                    <p>Trading activities may have tax implications depending on your jurisdiction. You are responsible for understanding and complying with applicable tax laws and regulations, including reporting and paying any taxes due. Picco Trading does not provide tax advice, and we recommend consulting with a qualified tax professional to understand your obligations.</p>

                                    <h3>10. Liquidity Risks</h3>
                                    <p>Certain financial instruments or markets may have limited liquidity, meaning there may not be sufficient buyers or sellers to facilitate the timely execution of trades at desired prices. Illiquidity can result in substantial losses if you are unable to close or modify a position quickly.</p>
                                    
                                    <h3>11. Technological and Operational Risks</h3>
                                    <p>Your access to the Site and services may be affected by technological or operational risks, including but not limited to:</p>

                                    <ul>
                                        <li><strong>Service Interruptions:</strong> Downtime, maintenance, or service outages may prevent you from accessing your account or executing trades.</li>
                                        <li><strong>Security Breaches:</strong> While we employ various security measures to protect your data, unauthorized access, hacking, or data breaches may still occur.</li>
                                        <li><strong>User Errors:</strong> Mistakes in entering orders, misinterpretation of information, or improper use of the platform may result in unintended trades or losses.</li>
                                    </ul>
                                    
                                    <h3>12. No Fiduciary Duty
                                    </h3>
                                    <p>Picco Trading is not your fiduciary or advisor. You acknowledge that we are providing you with access to a platform and tools to facilitate trading but do not act as your personal financial advisor. You assume full responsibility for your trading decisions and outcomes.</p>
                                    
                                    <h3>13. Indemnification</h3>
                                    <p>You agree to indemnify, defend, and hold harmless Picco Trading, its affiliates, officers, directors, employees, agents, and service providers from and against any claims, losses, damages, liabilities, costs, and expenses (including reasonable attorney's fees) arising from your use of our services, violation of this Risk Disclosure Statement, or infringement of any rights of another party.</p>

                                    <h3>14. Changes to This Risk Disclosure Statement
                                    </h3>
                                    <p>Picco Trading reserves the right to modify, amend, or update this Risk Disclosure Statement at any time without prior notice. Any changes will be posted on this page, and your continued use of the Site and services after any changes constitutes your acceptance of the updated terms.</p>



                                    <h3>15. Contact Information</h3>
                                    <p>If you have any questions or concerns about this Risk Disclosure Statement or our services, please contact us at:
                                    </p>

                                    <p><strong>Email:</strong> <a href="mailto:info@piccotrading.com">info@piccotrading.com</a></p>

                                    <p>By using the Picco Trading website and its services, you acknowledge that you have read, understood, and agreed to this Risk Disclosure Statement and accept the risks associated with trading.
                                    </p>


                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LegalDisclaimer
