import React, { useEffect } from 'react'

const TradingViewWidget = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
        script.async = true
        script.innerHTML = JSON.stringify({
            // "symbols": [
            //     {
            //         "proName": "FOREXCOM:SPXUSD",
            //         "title": "S&P 500 Index"
            //     },
            //     {
            //         "proName": "FOREXCOM:NSXUSD",
            //         "title": "US 100 Cash CFD"
            //     },
            //     {
            //         "proName": "FX_IDC:EURUSD",
            //         "title": "EUR to USD"
            //     },
            //     {
            //         "proName": "BITSTAMP:BTCUSD",
            //         "title": "Bitcoin"
            //     },
            //     {
            //         "proName": "BITSTAMP:ETHUSD",
            //         "title": "Ethereum"
            //     }
            // ],
            "symbols": [
                {
                    "description": "",
                    "proName": "NASDAQ:AAPL"
                },
                {
                    "description": "",
                    "proName": "AMEX:SPY"
                },
                {
                    "description": "",
                    "proName": "NASDAQ:QQQ"
                },
                {
                    "description": "",
                    "proName": "NASDAQ:NVDA"
                },
                {
                    "description": "",
                    "proName": "NASDAQ:GOOGL"
                },
                {
                    "description": "",
                    "proName": "NASDAQ:MSFT"
                },
                {
                    "description": "",
                    "proName": "NASDAQ:AMZN"
                },
                {
                    "description": "",
                    "proName": "NASDAQ:TSLA"
                },
                {
                    "description": "",
                    "proName": "NYSE:JPM"
                },
                {
                    "description": "",
                    "proName": "NYSE:GS"
                },
                {
                    "description": "",
                    "proName": "CME:BTC1!"
                }
            ],
            "showSymbolLogo": true,
            "isTransparent": false,
            "displayMode": "adaptive",
            "colorTheme": "light",
            "locale": "en"
        })

        document.getElementById('tradingview-widget-container').appendChild(script)

        return () => {
            try {
                if (document.getElementById('tradingview-widget-container')) {
                    document.getElementById('tradingview-widget-container').removeChild(script)
                }
            } catch (error) { }
        }
    }, [])

    return (
        <div className="tradingview-widget-container d-flex align-items-end" id="tradingview-widget-container" style={{ minHeight: 46 }}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    )
}

export default React.memo(TradingViewWidget)
