import React, { useState } from 'react';
import './contact-us.css'; // Assuming you have a separate CSS file for this component
import Social from './social'
import { Link } from 'react-router-dom';
import Alert from '../utils/alert';
import { Button } from 'react-bootstrap';

function ContactForm() {

  const [emailSendSuccess, setEmailSendSuccess] = useState(-1)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [formErrors, setFormErrors] = useState({})

  const [copied, setCopied] = useState(false)
  const email = "info@piccotrading.com"

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!formData.message) {
      errors.message = 'Message is required';
    }
    return errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {

      try {
        let response = await fetch('https://api.piccotrading.com/public/picco/contactUs', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        if (response.ok) {
          response = await response.json();
          setFormData({
            name: '',
            email: '',
            message: ''
          })
          if (response.status) {
            Alert.show("Thank you! Your message has been sent successfully,will get back to you.", true);
            setEmailSendSuccess(1);
          } else {
            Alert.show("Oops! Something went wrong. Please try again later.", false);
            setEmailSendSuccess(0);
          }
        } else {
          console.error('Failed to send message');
          // Handle the error, show an error message, or take appropriate action
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle network or other errors
      }
    } else {
      setFormErrors(errors);
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(email).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000)
    }).catch((err) => {
      console.error('Failed to copy text: ', err)
    })
  }

  return (
    <div className="contact_us">
      <div className="form-container">

        <div className="left-container">
          <div className="left-inner-container">
            <div className='page-title'> Contact Us </div>
            <div className='page-subtitle gradient-text mb-4 pb-4'> Questions, feedback, Insight in mind </div>
            <p>Your questions, feedback, and insights are vital to us. Whether you're curious about our services, need support, or want to share your trading journey, we're here to listen and assist. For any inquiries or messages, please don’t hesitate to contact us. </p>
            <p >
              <a href="mailto:info@piccotrading.com" style={{ textDecoration: 'none', color: '#EAEAEA', paddingRight: 5 }}>
                {email}
              </a>
              <a className="copy" onClick={handleCopy}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.25 3H8.25C8.05109 3 7.86032 3.07902 7.71967 3.21967C7.57902 3.36032 7.5 3.55109 7.5 3.75V7.5H3.75C3.55109 7.5 3.36032 7.57902 3.21967 7.71967C3.07902 7.86032 3 8.05109 3 8.25V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H15.75C15.9489 21 16.1397 20.921 16.2803 20.7803C16.421 20.6397 16.5 20.4489 16.5 20.25V16.5H20.25C20.4489 16.5 20.6397 16.421 20.7803 16.2803C20.921 16.1397 21 15.9489 21 15.75V3.75C21 3.55109 20.921 3.36032 20.7803 3.21967C20.6397 3.07902 20.4489 3 20.25 3ZM15 19.5H4.5V9H15V19.5ZM19.5 15H16.5V8.25C16.5 8.05109 16.421 7.86032 16.2803 7.71967C16.1397 7.57902 15.9489 7.5 15.75 7.5H9V4.5H19.5V15Z" fill="#0BE096" />
                </svg>
                {copied ? 'Copied!' : 'Copy'}
              </a>
            </p>
          </div>
        </div>

        <div className="right-container bg-gray rounded-4">
          <div className="right-inner-container">
            <form noValidate onSubmit={handleSubmit}>
              <h2>Let’s Connect!</h2>
              <div>
                <p className='m-0'>Name</p>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={formErrors.name ? 'input-error' : ''}
                  placeholder="Enter your name here"
                />
                {formErrors.name && <p className="error">{formErrors.name}</p>}
              </div>

              <div>
                <p className='m-0'>Email</p>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={formErrors.email ? 'input-error' : ''}
                  placeholder="example@email.com"
                />
                {formErrors.email && <p className="error">{formErrors.email}</p>}
              </div>

              <div>
                <p className='m-0'>Message</p>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className={formErrors.message ? 'input-error' : ''}
                  rows="4"
                  placeholder="Write your message here"
                />
                {formErrors.message && <p className="error">{formErrors.message}</p>}
              </div>
              <Button type="submit" variant="success" className='w-100 mt-1'>
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div >
  );
}

export default ContactForm;
