import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Card, Button, Row, Col } from 'react-bootstrap'
import './home.css'
import './about.css'
import FAQ from '../components/faq';
import ContactForm from '../components/contact-us'

const ContactUs = (props) => {
    return (
        <>
            <Helmet>
                <title>Contact us | Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Contact us | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="about-us-page">
                <div className="bg-dark page-padding">
                    <Container>
                        <ContactForm />
                        <div className="home-faq">
                            <div className='section-title gradient-text no-icon'> Frequently Asked Questions </div>
                            <FAQ />
                            <Card.Body className='bg-black mt-4 p-4 rounded-4 border-0'>
                                <Row>
                                    <Col md={8}>
                                        <dt className="faq-question">
                                            Didn't find your answer? 
                                        </dt>
                                        <dd className="faq-answer open pt-1">
                                        Feel free to contact us directly. Our team is always ready to assist you.
                                        </dd>
                                    </Col>
                                    <Col md={4} className='d-flex align-items-center justify-content-end'>
                                        <div className='banner-button'>
                                            <a href="mailto:info@piccotrading.com">
                                                <Button variant="light">
                                                    Send Email
                                                </Button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </div>
                    </Container>
                </div >
            </div >
        </>
    )
}

export default ContactUs
