import React from 'react'
import { Helmet } from 'react-helmet'

import Highlight from '../components/highlight'
import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <div className="home-container about_us">
            <Helmet>
                <title>Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="home-sections">
            <div className="home-section" style={{ justifyContent: "flex-start",paddingBottom:65 }}>
                    <div className="home-content02">
                        <h2 className="home-text07">Events</h2>
                        <Highlight
                            title="A Journey of Passion and Expertise in Trading "
                            description="This is sample upcomings page"
                        ></Highlight>
                    </div>
                  
                </div>

            </div>


            <div className="home-get-started1">
                <div className="home-content09">
                    <div className="home-heading14">
                        <h2 className="home-text77">Join Us on Our Journey</h2>
                        <span className="home-text78">
                            We invite you to be part of our story. Discover how PICCO Trading can transform your trading experience with insights, knowledge, and a community dedicated to your success.
            </span>
                    </div>
                    <div className="home-hero-buttons1">
                        <div className="home-ios-btn1">
                            {/* <img
                                alt="pastedImage"
                                src="/pastedimage-zmzg.svg"
                                className="home-apple1"
                            /> */}
                            <span className="home-caption6">Contact US</span>
                        </div>
                        <div className="home-android-btn1">
                            {/* <img
                                alt="pastedImage"
                                src="/pastedimage-ld65.svg"
                                className="home-android1"
                            /> */}
                            <span className="home-caption7">Subscribe Now</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalDisclaimer
