import React, { useState } from 'react';
import './faq.css';

const faqs = [
  {
    question: 'What Trading Insights Does Picco Offer?',
    answer: 'We provide insights into Futures, Options, Small Cap, and Large Cap stocks, along with automated trading bots to help you navigate and capitalize on market opportunities.'
  },
  {
    question: 'How Often Are Insights Updated?',
    answer: 'Our insights are updated in real-time, keeping you informed of market opportunities as they happen.'
  },
  {
    question: 'How Can I Contact Customer Support?',
    answer: `You can reach our customer support team via email or through our website's contact form. Visit the Contact Us page for more information and assistance.`
  },
  {
    question: 'Can I Try Picco Before Subscribing?',
    answer: 'We occasionally offer trial periods during select promotions throughout the year. To find out if a trial is currently available, please contact us via email.'
  },
  // {
  //   question: 'Is There a Contract or Minimum Subscription Period?',
  //   answer: 'No, there are no long-term contracts. Our subscriptions are flexible, allowing you to cancel anytime without penalties or hidden fees.'
  // },
  {
    question: 'How Do I Upgrade or Cancel My Subscription?',
    answer: 'You can manage your subscription anytime through your account settings. For additional assistance, feel free to reach out to our customer support team'
  },
  // {
  //   question: 'Didn\'t Find Your Answer?',
  //   answer: 'If you have a question that\'s not covered here, please don\'t hesitate to reach out to us directly. Our team is ready to assist you.'
  // },
  // {
  //   question: 'What Payment Methods Do You Accept?',
  //   answer: 'We accept various payment methods including credit cards, debit cards, and online payment platforms. For a full list, please visit our subscription page.'
  // },
  // {
  //   question: 'Are My Personal and Financial Details Safe?',
  //   answer: 'Absolutely. We use encrypted, secure technology to protect all your personal and financial information. Your privacy and security are paramount to us.'
  // }
];


// Define the SVG paths for the icons
const PlusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#0BE096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const MinusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#0BE096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <dt className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        {faq.question}
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </dt>
      <dd className={`faq-answer ${isOpen ? 'open' : ''}`}>
        {faq.answer}
      </dd>
    </div>
  );
};

const FAQ = () => {
  return (
    <dl className="faq-list">
      {faqs.map((faq, index) => (
        <FAQItem key={index} faq={faq} />
      ))}
    </dl>
  );
};

export default FAQ;
