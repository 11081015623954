import React, { useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import Card from 'react-bootstrap/Card'
import Placeholder from 'react-bootstrap/Placeholder'
import Form from 'react-bootstrap/Form'

const TopTrades = ({ max }) => {
  const [Loader, setLoader] = useState(false)

  const [Channels, setChannels] = useState([
    { name: 'Futures Trading Room', alerts: [] },
    { name: 'Stocks & Options Room', alerts: [] }
  ])
  const [SelectedChannel, setSelectedChannel] = useState(Channels[0])

  useEffect(() => {
    setLoader(true)

    const controller = new AbortController()
    const signal = controller.signal

    fetch('https://api.piccotrading.com/public/top-alerts', { signal })
      .then(response => response.json())
      .then(data => {
        setLoader(false)
        setChannels(Channel => {
          Channel = Channel.map(ch => {
            ch.alerts = data.data[ch.name]
            if (max) {
              ch.alerts = ch.alerts.slice(0, 3)
            }
            return ch
          })
          setSelectedChannel(Channel.find(ch => ch.alerts.length) || Channel[0])
          return Channel
        })
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          setLoader(false)
          console.error('Error fetching data:', error)
        }
      })

    return () => {
      controller.abort()
    }
  }, [max])

  function formatDateTime(dateTimeString) {
    const options = {
      timeZone: 'America/New_York',
      month: 'short',
      year: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    }
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateTimeString).getTime())
    return formattedDate
  }

  return (
    <>
      <Nav variant="underline" className='d-none d-md-flex'>
        {
          Channels.map((channel, i) => {
            return <Nav.Item key={i} onClick={() => setSelectedChannel(channel)}>
              <Nav.Link className={channel.name === SelectedChannel.name ? 'active' : ''}> {channel.name} Trades </Nav.Link>
            </Nav.Item>
          })
        }
      </Nav>

      <div data-bs-theme="dark" className='channel-select d-md-none'>
        <Form.Select value={SelectedChannel.name} onChange={e => setSelectedChannel(Channels.find(ch => ch.name === e.target.value))}>
          {
            Channels.map((channel, i) => {
              return <option key={i}>{channel.name}</option>
            })
          }
        </Form.Select>
      </div>

      <Row className='home-section-2 pt-4'>
        {
          Loader ? (
            <>
              {
                Array.from({ length: 3 }).map((_, i) => {
                  return <Col md={4} key={i} className='mt-4'>
                    <Card.Body>
                      <div className='text-gray'>
                        <Placeholder as={'h3'} animation="glow">
                          <Placeholder xs={3} />
                        </Placeholder>
                      </div>
                      <div className='card-text'>
                        <h4 className='text-success m-0 mt-4'>
                          <Placeholder animation="glow">
                            <Placeholder xs={2} />
                          </Placeholder>
                        </h4>
                        <div className='small sub-title'> PnL </div>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col>
                                <div className='title text-white'>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={3} />
                                  </Placeholder>
                                </div>
                                <div className='small sub-title'> Direction </div>
                              </Col>
                              <Col className='text-center'>
                                <div className='title text-white'>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={3} />
                                  </Placeholder>
                                </div>
                                <div className='small sub-title'> Entry Price </div>
                              </Col>
                              <Col className='text-end'>
                                <div className='title text-white'>
                                  <Placeholder animation="glow">
                                    <Placeholder xs={3} />
                                  </Placeholder>
                                </div>
                                <div className='small sub-title'> Exit Price </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div className='small text-gray mt-4'>
                        <Placeholder animation="glow">
                          <Placeholder xs={11} />
                        </Placeholder>
                      </div>
                    </Card.Body>
                  </Col>
                })
              }
            </>
          ) : (
            <>
              {
                SelectedChannel?.alerts.map((alert, i) => {
                  return <Col md={4} key={i} className='mt-4'>
                    <Card.Body>
                      <h3 className='text-gray'> {alert.ticker} </h3>
                      <div className='card-text'>
                        <h4 className='text-success m-0 mt-4'>+{alert.pnl}%</h4>
                        <div className='small sub-title'> PnL </div>
                        <hr />
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col>
                                <div className='title icon text-white'> <img src={alert.direction === 'Short' ? "/short.png" : "/long.png"} height={20} alt="directon" /> {alert.direction} </div>
                                <div className='small sub-title'> Direction </div>
                              </Col>
                              <Col className='text-center'>
                                <div className='title text-white'> {alert.entryPrice} </div>
                                <div className='small sub-title'> Entry Price </div>
                              </Col>
                              <Col className='text-end'>
                                <div className='title text-white'> {alert.closePrice || '-'} </div>
                                <div className='small sub-title'> Exit Price </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div className='small text-gray mt-4'> Last updated on {formatDateTime(alert.updatedAt)} (EST)  </div>
                    </Card.Body>
                  </Col>
                })
              }
            </>
          )
        }
      </Row>
    </>
  )
}

export default TopTrades