import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Container, Card, Button } from 'react-bootstrap'

import './home.css'
import './about.css'

const AboutUs = (props) => {
    return (
        <>
            <Helmet>
                <title>About us | Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="About us | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="about-us-page">
                <div className="home-banner our_story"></div>
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className='page-title'>Picco Trading – Your Premier Trading Partner</div>
                        <div className='page-subtitle gradient-text mb-4 pb-4'> Read to know more about our story </div>
                        <div className='our-story-text'>
                        At Picco, we believe trading should be bold, accessible, and rewarding. That’s why we’ve developed a robust platform that empowers you to take control of your trading journey. Whether you're new to trading or a seasoned professional, Picco stands as your trusted partner, offering the tools, insights, and expertise needed to navigate the markets with confidence and precision.                        </div>

                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Our Mission</div>
                            <div className='sub-title'> To empower traders at every level with innovative tools, expert insights, and a dynamic community. At Picco, we transform the trading experience, providing you with the confidence and precision to navigate markets effectively. We're not just a platform; we're your strategic partner in achieving lasting success. </div>
                        </Card.Body>

                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Why Choose Picco?</div>
                            <div className='sub-title'> Picco is more than just a trading platform—it’s a comprehensive solution crafted to empower your trading journey. Here’s what sets Picco apart:
                                <ul>
                                    <li><strong>Real-Time Trade Ideas: </strong> Gain instant access to expert trade ideas across Futures, Options, Small Cap, and Large Cap markets, ensuring you never miss an opportunity to stay ahead.</li>
                                    <li><strong>Intelligent Automation:</strong>  Leverage our AI-powered trading bots that execute strategies with precision to optimize your profits, all while you stay focused on what truly matters.</li>
                                    <li><strong> Vibrant Community:</strong>Join a global network of ambitious traders, engage in insightful discussions, and continuously sharpen your skills within a thriving community.</li>
                                    <li><strong>Private Trading Rooms:</strong> Lead your own private room, share exclusive content, and build a dedicated following to elevate your presence in the trading world.</li>
                                </ul>
                            </div>
                        </Card.Body>


                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> What Drives Us</div>
                            <div className='sub-title'> Empowering traders with the tools, insights, and community needed to make strategic decisions and achieve lasting success. </div>
                        </Card.Body>


                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Our Core Values</div>
                            <div className='sub-title'> At Picco, we believe trading should be accessible and empowering. We simplify the complexities to provide an intuitive platform for every trader, no matter their experience level.
                                <ul>
                                    <li><strong>Innovation:</strong> Delivering advanced tools and technologies to keep you ahead of the market.</li>
                                    <li><strong>Community:</strong> Your success is our success, so we cultivate a vibrant space for learning, growth, and support.</li>
                                    <li><strong>Transparency:</strong>Clear communication and honest insights—no hidden agendas.</li>
                                    <li><strong>Empowerment:</strong> Equipping you with the knowledge and tools to make confident trading decisions.</li>
                                </ul>
                            </div>
                        </Card.Body>

                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Join the Picco Community</div>
                            <div className='sub-title'> Picco is more than a platform—it’s a game-changer for your trading journey. Be inspired, stay motivated, and reach new heights with a community built around your growth and success. This is your opportunity to transform the way you trade.
                            </div>
                        </Card.Body>


                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'>Ready to Elevate Your Trading?</div>
                            <div className='sub-title'> Join Picco and discover a smarter, more profitable approach to trading. Your journey to success starts here. 
                                <br></br>
                                {/* CTA – Begin Your Journey with Picco */}
                            </div>  
                            <div className='banner-button mt-4 pt-2'>
                                <Link to={{ pathname: "https://portal.piccotrading.com/#/register" }} target="_blank">
                                    <Button variant="success">
                                    Begin Your Journey with Picco
                                    </Button>
                                </Link>
                            </div>                          
                            {/* <div className='banner-button mt-4 pt-2'>
                                <Link to={{ pathname: "https://portal.piccotrading.com/#/register" }} target="_blank">
                                    <Button variant="success">
                                        Subscribe Now
                                    </Button>
                                </Link>
                                <Link to="/contact-us">
                                    <Button variant="outline-light">
                                        Contact Us
                                    </Button>
                                </Link>
                            </div> */}
                        </Card.Body>

                        {/* <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Join us on our journey </div>
                            <div className='sub-title'> We invite you to be part of our story. Discover how PICCO Trading can transform your trading experience with insights, knowledge, and a community dedicated to your success </div>
                            <div className='banner-button mt-4 pt-2'>
                                <Link to={{ pathname: "https://portal.piccotrading.com/#/register" }} target="_blank">
                                    <Button variant="success">
                                        Subscribe Now
                                    </Button>
                                </Link>
                                <Link to="/contact-us">
                                    <Button variant="outline-light">
                                        Contact Us
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body> */}
                    </Container>
                </div>
            </div>
        </>
    )
}

export default AboutUs
