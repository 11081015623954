import React from 'react'
import { Container } from 'react-bootstrap'

import { Helmet } from 'react-helmet'
import './home.css'
import './about.css'
import './product.css'
import PricingSection from '../components/pricing'

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Our Products | Picco</title>
        <meta name="description" content="Description of the website" />
        <meta property="og:title" content="Our Products | Picco" />
        <meta property="og:description" content="Description of the website" />
      </Helmet>

      <div className="product-page">
        <div className='bg-dark page-padding'>
          <Container>
            <div className='page-title'> Products </div>
            <div className='page-subtitle gradient-text mb-4 pb-4'> Find the Product That Fuels Your Trading Success with Picco!</div>
            <PricingSection />
          </Container>
        </div>
      </div >
    </>
  )
}

export default ContactUs
