import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <>
            <Helmet>
                <title>Terms & Conditions | Picco</title>
                <meta name="description" content="Terms & Conditions" />
                <meta property="og:title" content="Terms & Conditions | Picco" />
                <meta property="og:description" content="Terms & Conditions" />
            </Helmet>

            <div className="legal">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className="home-sections legal_container">
                            <div className="home-section" style={{ justifyContent: "flex-start" }}>
                                <div className="home-content02">
                                    <div className='page-title mb-4'> Terms & Conditions </div>

                                    <span>
                                        <strong>Last Updated: September 5, 2024</strong>
                                    </span>

                                    <p>Welcome to Picco Trading. By accessing or using our website, <a href="www.piccotrading.com"> www.piccotrading.com </a> (the "Site"), or any services provided by Picco Trading ("we," "us," or "our"), you agree to comply with and be bound by these Terms and Conditions. Please read them carefully before using our Site or services.</p>

                                    <h3>1. Acceptance of Terms</h3>
                                    <p>By accessing or using our Site, you confirm that you have read, understood, and agree to these Terms and Conditions in their entirety. If you do not agree with any part of these terms, you must not use our Site or services.</p>

                                    <h3>2. Eligibility</h3>
                                    <p>You must be at least 18 years old or the legal age of majority in your jurisdiction to use our services. By using our Site, you represent and warrant that you meet these eligibility requirements. If you do not meet these requirements, you must not access or use our services.</p>

                                    <h3>3. User Accounts and Security</h3>
                                    <p>To access certain features of the Site, you may be required to create an account. You agree to provide accurate, complete, and up-to-date information and to maintain the security of your account credentials. You are responsible for all activities that occur under your account and agree to notify us immediately of any unauthorized use or security breach. Picco Trading is not liable for any loss or damage resulting from your failure to comply with these obligations.</p>

                                    <h3>4. Subscriptions and Payments</h3>
                                    <p>Picco Trading offers various subscription plans, some of which require payment. By subscribing to any of our plans, you agree to pay the fees specified for your chosen plan, including any applicable taxes. All subscriptions will automatically renew at the end of each term unless you cancel before the renewal date. You are responsible for managing your subscription and ensuring timely cancellation if you do not wish to renew. No refunds will be provided for partial use of the subscription period.</p>

                                    <h3>5. Use of Services</h3>
                                    <p>You agree to use our services only for lawful purposes and in compliance with these Terms and Conditions and all applicable laws and regulations. You shall not:</p>
                                    <ul>
                                        <li>Use the Site or services for any fraudulent or unlawful purpose.</li>
                                        <li>Impersonate any person or entity or falsely state or misrepresent your affiliation with a person or entity.</li>
                                        <li>Interfere with or disrupt the operation of the Site or services, including transmitting any malware, viruses, or harmful code.</li>
                                        <li>Attempt to gain unauthorized access to any portion of the Site, other accounts, computer systems, or networks connected to the Site.</li>
                                        <li>Use automated systems, such as bots or scrapers, to access, copy, or harvest data from the Site.</li>
                                    </ul>

                                    <h3>6. Intellectual Property Rights</h3>
                                    <p>All content on the Site, including but not limited to text, graphics, images, logos, software, and trademarks, is the exclusive property of Picco Trading or its licensors and is protected by copyright, trademark, and other intellectual property laws. You are prohibited from using, reproducing, distributing, modifying, or creating derivative works from any content without our express written consent. Unauthorized use may result in civil or criminal penalties.</p>

                                    <h3>7. Automated Trading Bots and Tools</h3>
                                    <p>Picco Trading may provide access to automated trading bots and tools designed to assist users in their trading activities. You acknowledge that these bots and tools are provided "as-is" and without any warranty of any kind. You use them at your own risk, and Picco Trading does not guarantee their performance or accuracy. You are responsible for monitoring your trading activities and making decisions based on your judgment.</p>

                                    <h3>8. Third-Party Links and Services</h3>
                                    <p>Our Site may contain links to third-party websites, services, or applications. These links are provided for your convenience, and Picco Trading does not endorse or assume any responsibility for the content, privacy practices, or policies of such third-party sites. You access these third-party sites at your own risk and should review their terms and conditions and privacy policies.</p>

                                    <h3>9. Disclaimer of Warranties</h3>
                                    <p>Picco Trading provides the Site and services on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation of the Site, the information, content, materials, or services provided. To the fullest extent permitted by law, we disclaim all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, and accuracy.</p>

                                    <h3>10. Limitation of Liability</h3>
                                    <p>To the maximum extent permitted by law, Picco Trading, its affiliates, employees, agents, licensors, or service providers shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, loss of profits, data, goodwill, or other intangible losses arising from:</p>
                                    <ul>
                                        <li>Your use or inability to use the Site or services.</li>
                                        <li>Unauthorized access to or alteration of your data or transmissions.</li>
                                        <li>Statements or conduct of any third party on the Site or services.</li>
                                        <li>Any other matter relating to the Site or services.</li>
                                    </ul>
                                    <p>This limitation of liability applies regardless of the legal theory under which such damages are sought.</p>

                                    <h3>11. Indemnification</h3>
                                    <p>You agree to indemnify, defend, and hold harmless Picco Trading, its affiliates, officers, directors, employees, agents, licensors, and service providers from and against any claims, losses, damages, liabilities, costs, and expenses (including reasonable attorney's fees) arising out of or related to your use of our services, violation of these terms, or infringement of any rights of another party.</p>

                                    <h3>12. User Content and Conduct</h3>
                                    <p>You are solely responsible for any content you post, upload, or transmit on the Site, including comments, feedback, or other materials ("User Content"). You agree not to post any content that is defamatory, obscene, abusive, or otherwise violates any applicable laws or the rights of others. Picco Trading reserves the right to remove any User Content that violates these terms or is otherwise objectionable at our sole discretion.</p>

                                    <h3>13. Modifications to the Site and Services</h3>
                                    <p>Picco Trading reserves the right to modify, suspend, or discontinue the Site or any services offered at any time without notice. We may also update these Terms and Conditions from time to time to reflect changes in our practices or legal requirements. Your continued use of the Site following any changes constitutes your acceptance of the updated terms.</p>

                                    <h3>14. Termination</h3>
                                    <p>Picco Trading may terminate or suspend your access to the Site or services at any time, with or without cause, and with or without notice. Upon termination, your right to use the Site will immediately cease. You remain liable for all obligations incurred before termination.</p>

                                    <h3>15. Privacy Policy</h3>
                                    <p>Your use of the Site and services is also governed by our Privacy Policy, which is incorporated into these Terms and Conditions by reference. Please review our Privacy Policy to understand our practices concerning your personal information.</p>

                                    <h3>16. Governing Law and Jurisdiction</h3>
                                    <p>These Terms and Conditions, and any disputes arising from or relating to your use of the Site, shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of laws principles. You consent to the exclusive jurisdiction and venue of the courts located in [Your Jurisdiction] for any legal action or proceeding arising from or related to these terms.</p>

                                    <h3>17. Entire Agreement</h3>
                                    <p>These Terms and Conditions, together with our Privacy Policy, constitute the entire agreement between you and Picco Trading concerning your use of the Site and services and supersede any prior or contemporaneous agreements, communications, or understandings, whether oral or written.</p>

                                    <h3>18. Severability</h3>
                                    <p>If any provision of these Terms and Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.</p>

                                    <h3>19. No Waiver</h3>
                                    <p>No waiver of any term or condition in these Terms and Conditions shall be deemed a further or continuing waiver of such term or any other term, and Picco Trading's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</p>


                                    <h3>20. Contact Information</h3>
                                    <p>If you have any questions, concerns, or requests regarding these Terms and Conditions, please contact us at:</p>
                                    <p><strong>Email:</strong> <a href="mailto:info@piccotrading.com">info@piccotrading.com</a></p>
                                    <p><strong>Address:</strong> </p>
                                    <p><strong>Phone:</strong> </p>

                                    <p>By using the Picco Trading website and its services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
                                </div>

                            </div>




                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LegalDisclaimer
