import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Container, Card, Button, Row, Col } from 'react-bootstrap'
// import { InstagramEmbed } from 'react-social-media-embed'

import './home.css'
import './about.css'

const RinuGoldAI = () => {
    return (
        <>
            <Helmet>
                <title>Discover RINU AI | Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Discover RINU AI | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="about-us-page">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className='page-title'>Discover RINU AI</div>
                        <div className='page-subtitle gradient-text mb-4 pb-4'> Unlock Your Trading Potential with Automated Precision </div>


                        <Row className='home-section-2 pt-4'>
                            <div className='my-4 py-4'>
                                <h2> Supercharge Your Trading Strategy with RINUGold AI </h2>
                                <div className='our-story-text'>
                                    Step into the future of trading with <strong>RINUGold AI</strong>, the ultimate tool for traders who want to maximize their profit potential with ease. Powered by advanced algorithms and real-time market data, RINUGold AI is designed to generate monthly profits with precision, all while you enjoy a hands-free trading experience. Experience the next level of automated trading – where the heavy lifting is done for you, allowing you to trade smarter, faster, and more efficiently, all while enjoying a completely hands-free experience.
                                </div>
                                <br />
                                <div className='our-story-text'>
                                    Don’t just trade. Trade with  <strong>RINUGold AI</strong> and unlock your full profit potential with confidence and ease!
                                </div>
                            </div>
                        </Row>

                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Automated Trading at Its Best </div>
                            <div className='sub-title pt-4'> No more staring at charts, analyzing endless data, or missing out on key opportunities. With <strong>RINUGold AI</strong>, trading is automated, streamlined, and simplified. Whether you're an experienced trader or just starting out, RINUGold AI is built to deliver reliable performance. Set it, forget it, and watch as it executes high-probability trades designed to maximize your returns.
                            </div>
                        </Card.Body>


                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Why Choose RINUGold AI?</div>
                            <div className='sub-title pt-4'>
                                <ul>
                                    <li><strong>🎯 Consistent Monthly Profit Targets:</strong> <strong>RINUGold AI</strong> is engineered to deliver steady, reliable returns each month, using state-of-the-art automation and market-driven strategies to maximize your profits with precision.</li>
                                    <li><strong>🤖 Hands-Free Automated Trading:</strong> Let <strong>RINUGold AI</strong> handle the heavy lifting while you focus on the bigger picture. Simply set your preferences, and let the bot trade for you.</li>
                                    <li>Let <strong>RINUGold AI</strong> take control of the hard work. Set your preferences, sit back, and watch as the bot executes trades on your behalf, freeing you to focus on your overall strategy.</li>
                                    <li><strong>🔒 Advanced Risk Management:</strong> <strong>RINUGold AI</strong> includes tailored risk management protocols to protect your capital and optimize trade entries and exits.</li>
                                </ul>
                            </div>
                        </Card.Body>


                        {/* <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Real-Time Transparency – Track Your Bot’s Success</div>
                            <div className='sub-title pt-4'> See exactly how <strong>RINUGold AI</strong>  performs! We believe in total transparency, which is why we provide real-time updates on our bot's daily performance. Monitor its profit/loss, number of trades, win rate, and maximum drawdown, and witness the bot’s success firsthand.
                            </div>
                        </Card.Body> */}


                        {/* <Card.Body className='join-us bg-gray p-0'>
                            <InstagramEmbed width="100%" url="https://www.instagram.com/piccotrading/" style={{ borderRadius: 16 }} />
                        </Card.Body> */}

                        {/* <Card.Body className='join-us bg-gray p-4'>
                            <Row>
                                <Col md={6}>
                                    <InstagramEmbed width="100%" url="https://www.instagram.com/piccotrading/" />
                                </Col>
                                <Col md={6}>
                                    <InstagramEmbed width="100%" url="https://www.instagram.com/p/DBHScY7SNtV/?utm_source=ig_embed&amp;utm_campaign=loading" />
                                </Col>
                            </Row>
                        </Card.Body> */}


                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'> Why RINUGoldAI is a Game-Changer:</div>
                            <div className='sub-title pt-4'>
                                <ul>
                                    <li><strong>Maximize Your Profits, Effortlessly:</strong> Target consistent, automated returns every day without needing to monitor the market 24/7.</li>
                                    <li><strong>Cutting-Edge Technology:</strong>  Leverage the power of advanced algorithms and real-time data to secure high-probability trades.</li>
                                </ul>
                            </div>
                        </Card.Body>


                        <Card.Body className='join-us bg-gray'>
                            <div className='section-title gradient-text no-icon mt-0 mb-2'>Ready to Elevate Your Trading?</div>
                            <div className='sub-title pt-4'> Why waste time and energy when you can let automation do the work for you? <strong>RINUGold AI</strong> takes your trading to new heights with minimal effort on your part. Achieve more, with less.
                                <br></br>
                                Take control today – sign up and start trading smarter.
                            </div>
                            <div className='banner-button mt-4 pt-2'>
                                <Link to={{ pathname: "/products" }} target="">
                                    <Button variant="success">
                                        Get Started Now!
                                    </Button>
                                </Link>
                            </div>
                        </Card.Body>


                    </Container>
                </div>
            </div>
        </>
    )
}

export default RinuGoldAI