import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import TopTrades from '../components/top-trades'
import './home.css'
import './about.css'

const OurTopTrades = () => {
    return (
        <>
            <Helmet>
                <title>Our Trades | Picco</title>
                <meta name="description" content="Our top 10 trades in Options, futures and Small & Large Caps" />
                <meta property="og:title" content="Out Trades | Picco" />
                <meta property="og:description" content="Our top 10 trades in Options, futures and Small & Large Caps" />
            </Helmet>

            <div className="our-trade-page">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className='page-title mb-4'> Top Trades </div>
                        <TopTrades />
                    </Container>
                </div>
            </div >
        </>
    )
}

export default OurTopTrades
