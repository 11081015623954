import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Highlight from '../components/highlight'
import Point from '../components/point'
import WhyRegister from '../components/register/why-register'
import './home.css'
import './about.css'

const ContactUs = (props) => {
    return (
        <div className="home-container about_us register">
            <Helmet>
                <title>Sign up | Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Sign up | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            {/* <div className="home-sections">
                <div className="home-section" style={{ justifyContent: "flex-start" }}>
                    <div className="home-content02">
                        <h2 className="home-text07">Join Our Community of Enlightened Traders</h2>
                        <Highlight
                            title=""
                            description="Embark on a journey of market exploration with PICCO Trading. Gain access to exclusive insights and analyses that will elevate your trading strategy. Signing up is simple, secure, and the first step towards transforming your trading experience."
                        ></Highlight>
                    </div>
                    <Link to={{pathname : "https://portal.piccotrading.com/#/register"}} target="_blank" className="home-navlink1">
                <div className="home-get-started" style={{margin : "0 auto"}}>
                  <span className="home-sign-up">Register now</span>
                </div>
              </Link>
                </div>
            </div> */}


            <WhyRegister/>

            <div className="home-section1" style={{paddingTop:0}}>
                <div className="home-content03">
                    <div className="home-heading02">
                        <h2 className="home-text09 gradient-text">How to Sign Up</h2>
                        <span className="home-text10">

                        </span>
                    </div>
                    <div className="home-content04">
                        <div className="home-points">
                            <Point isTick={false} number={1} text="Choose Your Plan: Select from our range of subscription plans tailored to meet your trading needs."></Point>
                            <Point isTick={false} number={2} text="Create Your Account: Fill in your details to create your personal PICCO Trading account. Your journey towards insightful trading starts here."></Point>
                            <Point isTick={false} number={3} text="Payment Process: Securely complete your subscription with our encrypted payment gateway. We ensure the safety and confidentiality of your financial information."></Point>
                            <Point number={4} isTick={false} text="Access Insights: Once your subscription is active, dive into our insights and start making informed trading decisions."></Point>
                        </div>
                        <Link to="/products" className="home-navlink1">
                            <div className="home-get-started">
                                <span className="home-sign-up">Register Now</span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="home-image-register"></div>
            </div>

            {/* <div className="home-features why_sign_up">
                <div className="home-header2">
                    <div className="home-heading03">
                        <h2 className="home-text17 gradient-text">Why Sign Up?</h2>
                    </div>
                </div>
                <div className="home-feature-list">
                    <Feature
                        title="Exclusive Market Insights"
                        thumbnail="/vector6113-r6dl.svg"
                        description="Stay ahead of the curve with our comprehensive analyses of futures, options, and equity markets."
                    ></Feature>

                    <Feature
                        title="Customized Experience"
                        thumbnail="/vector6113-r6dl.svg"
                        description="Tailor your feed to what matters most to you - from small cap wonders to large cap leaders."
                    ></Feature>
                    <Feature
                        title="Continuous Learning"
                        thumbnail="/vector6113-r6dl.svg"
                        description="Benefit from our educational approach to trading, enhancing your skills and knowledge."
                    ></Feature>

                    <Feature
                        title="Community and Support"
                        thumbnail="/vector6113-r6dl.svg"
                        description="Join a community of traders and gain access to our dedicated support team."
                    ></Feature>
                </div>
            </div> */}

            <div className="home-sections promise" style={{paddingBottom:96}}>
                <div className="home-section">
                    <div className="home-content02">
                        <h2 className="home-text07 gradient-text">Our Promise</h2>
                        <Highlight
                            description="Security and Privacy: Your personal and financial information is guarded with the utmost security and confidentiality."
                            title=""
                        ></Highlight>
                        <span className="home-title" style={{ fontSize: 16, lineHeight: "25px" }}>No Hidden Fees: Transparency is key. The price you see is the price you pay.</span>
                        <span className="home-title cancel_txt" style={{ fontSize: 16, lineHeight: "0px" }}>Easy Cancellation: You're in control. Cancel your subscription anytime, no questions asked.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
