import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <>
            <Helmet>
                <title>Electronic Signature Agreement | Picco</title>
                <meta name="description" content="Privacy policy" />
                <meta property="og:title" content="Privacy policy | Picco" />
                <meta property="og:description" content="Privacy policy" />
            </Helmet>

            <div className="legal">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className="home-sections legal_container">
                            <div className="home-section" style={{ justifyContent: "flex-start" }}>
                                <div className="home-content02">
                                    <div className='page-title mb-4'> Electronic Signature Agreement </div>

                                    <span>
                                        <strong>Last Updated: September 5, 2024</strong>
                                    </span>

                                    <p>This Electronic Signature Agreement ("Agreement") sets forth the terms and conditions under which Picco Trading ("we," "us," "our," or "Company") accepts electronic signatures from users ("you," "your," or "Subscriber") in connection with the use of our website, <a href="www.piccotrading.com"> www.piccotrading.com  </a> (the "Site"), and any related services. By providing an electronic signature, you agree to be bound by this Agreement and acknowledge the legal validity of electronic signatures and records.</p>

                                    <h2>1. Consent to Use Electronic Signatures and Records</h2>
                                    <p>By clicking “I Agree,” “Accept,” or any similar button or checkbox during your use of our Site or services, or by otherwise providing your electronic signature, you agree to the use of electronic communications, electronic records, and electronic signatures in place of physical documents and handwritten signatures for all purposes related to your use of Picco Trading’s services.
                                    </p>

                                    <h3>2. Legal Effect of Electronic Signatures</h3>
                                    <p>Your electronic signature has the same legal effect as a handwritten signature. By using an electronic signature, you acknowledge and agree that:</p>

                                    <ul>
                                        <li>All agreements, notices, disclosures, and other communications that we provide to you
                                            electronically satisfy any legal requirement that such communications be in writing.</li>
                                        <li>You are solely responsible for understanding the terms and implications of any
                                            agreement or document you sign electronically.</li>
                                        <li>Your electronic signature is binding and enforceable under applicable laws and
                                            regulations.</li>
                                    </ul>

                                    <h3>3. Use of Electronic Signatures for Transactions</h3>
                                    <p>By agreeing to this Agreement, you authorize Picco Trading to accept your electronic signature
                                        for the following transactions, including but not limited to:</p>

                                    <ul>
                                        <li>Subscription agreements, including any amendments, renewals, or cancellations.</li>
                                        <li>Payment authorizations, billing consents, and fee agreements.</li>
                                        <li>Risk disclosures, privacy policies, terms of service, and other compliance documents.</li>
                                        <li>Any other agreements, forms, or records required for your use of Picco Trading’s services.</li>
                                    </ul>


                                    <h3>4. Opt-Out or Withdrawal of Consent</h3>
                                    <p>You have the right to withdraw your consent to use electronic signatures at any time by
                                        contacting us at <a href="mailto:info@piccotrading.com">info@piccotrading.com</a>. However, withdrawing your consent may affect
                                        your ability to access and use certain features of our services. If you withdraw consent, we
                                        reserve the right to terminate or suspend your access to services that require electronic
                                        signatures.</p>

                                    <h3>5. Record Retention and Access</h3>
                                    <p>Picco Trading will provide you with access to electronically signed documents via your account
                                        on the Site or by email. It is your responsibility to retain a copy of any electronically signed
                                        documents for your records. We will maintain electronic records for as long as required by
                                        applicable laws, regulations, or our internal policies.</p>

                                    <h3>6. Security and Authenticity of Electronic Signatures</h3>
                                    <p>Picco Trading employs reasonable and industry-standard security measures to ensure the
                                        integrity, authenticity, and confidentiality of electronic signatures. However, you acknowledge
                                        that:</p>

                                    <ul>
                                        <li>Electronic transmissions are not entirely secure and may be subject to interception or
                                            unauthorized access.</li>
                                        <li>You are responsible for maintaining the security and confidentiality of your access
                                            credentials and electronic devices used to provide your electronic signature.</li>
                                        <li>You must immediately notify us of any suspected or actual unauthorized use of your
                                            electronic signature or account.</li>
                                    </ul>

                                    <h3>7. Amendments to the Agreement</h3>
                                    <p>Picco Trading reserves the right to amend, modify, or update this Agreement at any time without
                                        prior notice. Any changes to this Agreement will be posted on our Site, and your continued use
                                        of electronic signatures after such changes constitutes your acceptance of the updated terms.</p>

                                    <h2>8. Acknowledgment and Agreement</h2>
                                    <p>By providing your electronic signature, you acknowledge and agree that:</p>

                                    <ul>
                                        <li>You have read, understood, and consent to the terms and conditions of this Agreement.</li>
                                        <li>You are capable of receiving, accessing, and retaining electronic communications.</li>
                                        <li>You agree that your electronic signature is legally binding and enforceable, and you
                                            waive any future right to challenge its validity or enforceability.</li>
                                    </ul>



                                    <h3>9. Limitation of Liability</h3>
                                    <p>To the fullest extent permitted by law, Picco Trading, its affiliates, employees, agents, licensors, or service providers shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to loss of profits, revenue, data, or goodwill, arising from or relating to your use of electronic signatures or any electronic communications or records.</p>


                                    <h3>10. Contact Information</h3>
                                    <p>If you have any questions or concerns regarding this Agreement or your use of electronic
                                        signatures, please contact us at:</p>

                                    <p><strong>Email:</strong> <a href="mailto:info@piccotrading.com">info@piccotrading.com</a></p>

                                    <p>By using an electronic signature on the Picco Trading website, you agree to the terms and
                                        conditions outlined in this Agreement and confirm that you understand the legal significance and
                                        enforceability of your electronic signature.</p>


                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LegalDisclaimer
