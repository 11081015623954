import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import './style.css'
import Navbar from './components/navbar'
import Footer from './components/footer'
import Home from './views/home'
import AboutUs from './views/about-us'
import ContactUs from './views/contact-us'
import Products from './views/products'
import RinuGoldAI from './views/rinu-gold-ai'
import Register from './views/register'
import Legal from './views/legal-disclaimer2'
import PrivacyPolicy from './views/privacy-policy'
import Terms from './views/terms'
import Upcomings from './views/upcoming'
import Testimonials from './views/testimonials'
import OurTrades from './views/our-trades'
import NotFound from './views/not-found'
import ScrollToTopOnRouteChange from './components/scroll-top'

import MarketingAndCommunicationConsentForm from './views/marketing-and-communication-consent-form'
import DataProtectionNotice from './views/data-protection-notice'
import ElectronicSignatureAgreement from './views/electronic-signature-agreement'
import RiskDisclosureStatement from './views/risk-disclosure-statement'
import SubscriberAgreement from './views/subscriber-agreement'


const App = () => {
  return (
    <Router>
      <ScrollToTopOnRouteChange />
      <Navbar />
      <Switch>
        <Route onEnter={() => window.location.reload()} component={Home} exact path="/" />
        <Route component={AboutUs} exact path="/about-us" />
        <Route component={ContactUs} exact path="/contact-us" />
        <Route component={Products} exact path="/products" />
        <Route component={RinuGoldAI} exact path="/rinu-gold-ai" />
        <Route component={Register} exact path="/sign-up" />
        <Route component={Legal} exact path="/disclaimer" />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route component={Terms} exact path="/terms" />
        <Route component={Upcomings} exact path="/upcomings" />
        <Route component={Testimonials} exact path="/testimonials" />
        <Route component={OurTrades} exact path="/trades" />
        <Route component={MarketingAndCommunicationConsentForm} path="/marketing-and-communication-consent-form" />
        <Route component={DataProtectionNotice} path="/data-protection-notice" />
        <Route component={ElectronicSignatureAgreement} path="/electronic-signature-agreement" />
        <Route component={RiskDisclosureStatement} path="/risk-disclosure-statement" />
        <Route component={SubscriberAgreement} path="/subscriber-agreement" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
      <Footer />
    </Router>
  )
}

// ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('app'))
ReactDOM.render(<App />, document.getElementById('app'))
