import React from 'react'
import { Helmet } from 'react-helmet'

import Quote from '../components/quote'
import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <div className="home-container about_us">
            <Helmet>
                <title>Testimonials | Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Testimonials | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

           <div className="home-testimonials" style={{marginTop:0,background:'transparent'}}>
            <div className="home-heading02" style={{flexDirection:"initial",paddingTop:0}}>
                        <h2 className="home-text09" style={{color:"#214889"}}>What Our Subscribers Say</h2>
                        </div><br/><br/>
                
                <div className="home-content07">
                    <div id="quotes" className="home-quotes">
                        {/* <div className="quote active-quote">
                            <Quote rootClassName="quote-root-class-name"></Quote>
                        </div> */}
                        <div className="quote active-quote">
                            <Quote
                            quoteTitle='"A Game Changer in Trading Insights"'
                                quote='
                                "Since subscribing to PICCO Trading, my perspective on market trends has drastically improved. Their insights are clear, concise, and incredibly insightful. I appreciate the non-advisory approach, which empowers me to make informed decisions."'
                                title="Day Trader"
                                author="Ethan R."
                                avatar="./avtar1.jpg"
                                rootClassName="quote-root-class-name"
                            ></Quote>
                        </div>


<div className="quote">
                            <Quote
                            quoteTitle='"Invaluable Resource for Market Trends"'
                                quote='
                                "PICCO Trading has become an invaluable part of my trading routine. The depth of analysis provided, especially for Small Cap stocks, is top-notch. Its refreshing to see a service that focuses on education and transparency"'
                                title="Options Trader"
                                author="Jordan K."
                                avatar="./avtar2.jpg"
                                rootClassName="quote-root-class-name"
                            ></Quote>
                        </div>


                        <div className="quote">
                            <Quote
                            quoteTitle='"Transformed My Trading Strategy"'
                                quote='
                                "The insights from PICCO Trading have transformed the way I approach Futures. The detailed breakdowns and market observations have given me a new level of understanding and confidence in my trades"'
                                title="Equity Trader"
                                author="Alexandra P."
                                avatar="./avtar3.jpg"
                                rootClassName="quote-root-class-name"
                            ></Quote>
                        </div>

                         <div className="quote">
                            <Quote
                            quoteTitle='"Highly Recommended for Serious Traders"'
                                quote='
                                "If you are serious about trading, PICCO Trading is a must-have. The range of insights across various markets, especially Large Cap stocks, is impressive. Its helped me to diversify and strengthen my trading portfolio"'
                                title="Futures Trader"
                                author="Liam T."
                                avatar="./avtar4.jpg"
                                rootClassName="quote-root-class-name"
                            ></Quote>
                        </div>

                       
                    </div>
                    </div>
                    </div>


            <div className="home-get-started1">
                <div className="home-content09">
                    <div className="home-heading14">
                        <h2 className="home-text77">Join Us on Our Journey</h2>
                        <span className="home-text78">
                            We invite you to be part of our story. Discover how PICCO Trading can transform your trading experience with insights, knowledge, and a community dedicated to your success.
            </span>
                    </div>
                    <div className="home-hero-buttons1">
                        <div className="home-ios-btn1">
                            {/* <img
                                alt="pastedImage"
                                src="/pastedimage-zmzg.svg"
                                className="home-apple1"
                            /> */}
                            <span className="home-caption6">Contact US</span>
                        </div>
                        <div className="home-android-btn1">
                            {/* <img
                                alt="pastedImage"
                                src="/pastedimage-ld65.svg"
                                className="home-android1"
                            /> */}
                            <span className="home-caption7">Subscribe Now</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalDisclaimer
