import React from 'react';
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import SaleImage from './../assets/hot-sale.png'

const PricingSection = () => {
    const plans = [
        {
            title: 'Picco Trading Hub',
            price: 0,
            oldPrice: 50,
            features: [
                ' Free Access to a Community of Traders',
                ' Real-Time Scanners & Charts',
                ' Live Commentary',
                ' Expert Trade Guidance',
                ' Exclusive Expert Q&A Sessions'
            ],
            mostPopular: false,
            description: [`Connect, learn, and grow with traders from all experience levels in our free-to-join trading forum. Whether you're a seasoned pro or just starting your trading journey, you'll have access to real-time scanners, live charts, and expert trade guidance during market hours. Plus, gain valuable insights through comprehensive risk management and technical analysis guides provided by top veteran traders.`]
        },
        {
            title: 'Futures Bot',
            price: 350,
            oldPrice: 500,
            features: [
                ' Automated Trading',
                ' Proven Strategy',
                ' Built-In Risk Management',
                ' Compatible with Live & Funded Accounts',
                ' Set & Forget'
            ],
            mostPopular: false,
            description: [`Our advanced trading bot uses cutting-edge pattern recognition to execute high-potential trades in real time. Designed for minimal oversight and long-term growth, this bot helps traders generate consistent monthly income with optimized strategies.`]
        },
        {
            title: 'Futures Package (Bot & Trading Room)',
            price: 450,
            oldPrice: 900,
            features: [
                ' Automated Trading Bot',
                ' 2 - 5 Daily Trade Alerts',
                ' Complete Risk Management',
                ' Daily Trading Plan',
                ' Expert Trade Analysis'
            ],
            mostPopular: true,
            description: [`Get the best of both worlds with our Futures Trading Package—an automated trading bot for hands-free execution and 2-5 daily trade alerts with real-time analysis. Ideal for traders using live or funded accounts, this package delivers consistent results and expert strategies.`]
        },
        {
            title: 'Futures Trading Room',
            price: 150,
            oldPrice: 400,
            features: [
                ' Profit-Driven',
                ' Real-Time Alerts',
                ' Long & Short Trade Alerts with Exits',
                ' Daily Plan with Key Levels',
                ' Live Chat with Traders'
            ],
            mostPopular: false,
            description: [`Maximize your futures trading potential with our comprehensive Futures Trading Alert Service. Get 2-5 expertly curated trade alerts daily, capturing 5-15 points per trade, with clear long and short entry/exit signals. Stay ahead with a daily trading plan featuring key levels. Engage in live chat and access risk management strategies for optimal trading decisions.`]
        },
        {
            title: 'Stocks & Options Room',
            price: 150,
            oldPrice: 200,
            features: [
                ' Proven Track Record',
                ' Balanced Risk & Reward Strategy',
                ' Real-Time Alerts',
                ' Daily Watchlist',
                ' Market Commentary'
            ],
            mostPopular: false,
            description: [`Stay ahead of the market with our options and stock alert service. Receive timely alerts on put and call strategies for options, as well as small-cap and large-cap stock trades, helping you execute profitable trades with precision and confidence.`]
        }

    ]

    return (
        <div className="pricing_cards">
            <div className="container row m-0">
                {plans.map((plan, index) => {
                    let { title, price, oldPrice, features, description, mostPopular, comingSoon } = plan
                    return <div key={index} className="col-md-4 mt-4">
                        <div className="card-container">
                            <div className="card">
                                <div>
                                    <div className="cardHeader">
                                        {
                                            title === 'Futures Package (Bot & Trading Room)'
                                                ? (
                                                    <>
                                                        Futures Package
                                                        <br />(Bot & Trading Room)
                                                    </>
                                                ) : title
                                        }
                                        {mostPopular && <Badge bg="gradient">Most Popular</Badge>}
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='price m-0'>
                                            <div className='text-success'> {price === 0 ? 'FREE' : `$${price}`} </div>
                                        </div>
                                        {oldPrice && (
                                            <div className='d-flex align-items-center'>
                                                <img src={SaleImage} width={50} alt="offer"/>
                                                <h4 className='text-danger m-0 ms-4'> Fall <br />Discount</h4>
                                            </div>
                                        )}
                                    </div>
                                    <small style={{ opacity: price === 0 ? 0 : 1 }}>/ Month</small>
                                    {oldPrice && (
                                        <>
                                            <p className="price text-decoration-line-through mt-2">
                                                ${oldPrice}
                                            </p>
                                            <small>/ Month</small>
                                        </>
                                    )}
                                    <ul className="featuresList">
                                        {features?.map((feature, index) => (
                                            <li key={index} className="feature">
                                                <span className="checkmark">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M20.7243 6.31052C21.1051 6.71054 21.0895 7.34352 20.6895 7.72431L9.13394 18.7243C8.9366 18.9122 8.67116 19.0114 8.399 18.999C8.12683 18.9866 7.8715 18.8637 7.69204 18.6587L3.24759 13.5818C2.88381 13.1662 2.92578 12.5344 3.34133 12.1707C3.75688 11.8069 4.38865 11.8488 4.75243 12.2644L8.51013 16.5568L19.3105 6.2757C19.7106 5.89491 20.3435 5.9105 20.7243 6.31052Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                    <span className="ms-2">{feature}</span>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                    {description && <div className="feature">{description}</div>}
                                </div>
                                {comingSoon ? (
                                    <Button variant="secondary" className="w-100" disabled>
                                        Coming Soon
                                    </Button>
                                ) : (
                                    <Link to={{ pathname: 'https://portal.piccotrading.com' }} target="_blank">
                                        <Button variant={'success w-100'}>
                                            Subscribe Now
                                        </Button>
                                        {/* <Button variant={mostPopular ? 'success w-100' : 'outline-light w-100'}>
                                            Subscribe Now
                                        </Button> */}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
};

export default PricingSection;
