import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <>
            <Helmet>
                <title>Subscriber Agreement | Picco</title>
                <meta name="description" content="Privacy policy" />
                <meta property="og:title" content="Privacy policy | Picco" />
                <meta property="og:description" content="Privacy policy" />
            </Helmet>

            <div className="legal">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className="home-sections legal_container">
                            <div className="home-section" style={{ justifyContent: "flex-start" }}>
                                <div className="home-content02">
                                    <div className='page-title mb-4'> Subscriber Agreement </div>
                                    <span>
                            <strong>Last Updated: September 5, 2024</strong>
                        </span>
                        
                        <p>This Subscriber Agreement ("Agreement") is a binding legal contract between you ("Subscriber," "you," or "your") and Picco Trading ("we," "us," "our," or "Company"). By subscribing to our services, accessing our website, <a href="www.piccotrading.com"> www.piccotrading.com </a> (the "Site"), or using any of our products or services, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, you must not use our Site or services.</p>
                            

                                    <h2>1. Eligibility</h2>
                                    <p>To subscribe to Picco Trading's services, you must be at least 18 years old or the legal age of majority in your jurisdiction. By subscribing, you represent and warrant that you meet these eligibility requirements and that all information you provide during the subscription process is accurate, complete, and current.</p>

                                    <h3>2. Description of Services</h3>
                                    <p>Picco Trading offers various subscription plans that provide access to different products, services, and features, including but not limited to:</p>

                                    <ul>
                                        <li><strong>Trade Alerts:</strong> Access to real-time trade alerts, market analysis, and insights.</li>
                                        <li><strong>Automated Trading Bots:</strong> Use of proprietary automated trading bots for executing trades.</li>
                                        <li><strong>Educational Content:</strong> Access to tutorials, webinars, and educational resources.</li>
                                        <li><strong>Community Engagement:</strong> Participation in community forums, discussions, and live sessions with trading experts.</li>
                                        <li><strong>Private Trading Rooms:</strong> Option to host private trading rooms to manage personal trading communities.</li>
                                    </ul>

                                    <p>The details, pricing, and terms of each subscription plan are provided on our Site and are subject to change at our sole discretion.</p>


                                    <h3>3. Subscription and Payment Terms</h3>

                                    <ul>
                                        <li><strong>Subscription Fees:</strong>  By subscribing to our services, you agree to pay all applicable subscription fees and any associated taxes. All fees are non-refundable except as expressly stated in this Agreement or required by law.</li>
                                        <li><strong>Payment Methods:</strong> You must provide valid payment information, such as a credit card or other accepted payment methods, and authorize Picco Trading to charge your chosen payment method for the subscription fees. You agree to keep your payment information up to date.</li>
                                        <li><strong>Automatic Renewal:</strong> Subscriptions automatically renew at the end of each term unless canceled by you before the renewal date. You authorize Picco Trading to charge the applicable subscription fee to your payment method on each renewal date unless you cancel your subscription in accordance with Section 4.</li>
                                        <li><strong>Late Payments:</strong> If we cannot process your payment for any reason, you are responsible for promptly updating your payment information and ensuring payment. Failure to do so may result in suspension or termination of your subscription.</li>
                                    </ul>


                                    <h3>4. Cancellation and Refund Policy</h3>
                                    <ul>
                                        <li><strong>Cancellation by Subscriber:</strong> You may cancel your subscription at any time through your account settings on the Site. Cancellation will be effective at the end of the current subscription term, and you will not receive a refund for any unused portion of the term.</li>
                                        <li><strong>Cancellation by Picco Trading:</strong>  Picco Trading reserves the right to terminate or suspend your subscription at any time, with or without cause, effective immediately. In such cases, we may, at our discretion, provide a pro-rata refund for the remaining unused portion of your subscription term.</li>
                                        <li><strong>Refund Policy:</strong>  Refunds will only be provided in cases where required by law or at Picco Trading's sole discretion. All refund requests must be made within 30 days of the original transaction date.</li>
                                    </ul>


                                    <h3>5. Use of Services</h3>
                                    <p>By subscribing to our services, you agree to:</p>

                                    <ul>
                                        <li>Use the services only for lawful purposes and in accordance with this Agreement and all applicable laws and regulations.</li>
                                        <li>Not share, transfer, sublicense, or resell your subscription or access to the services to any third party.</li>
                                        <li>Not engage in any unauthorized activities, including but not limited to hacking, data scraping, or distributing malicious software.</li>
                                        <li>Not manipulate, disrupt, or interfere with the functionality of the Site or services.</li>
                                    </ul>

                                    <h3>6. Subscriber Responsibilities</h3>
                                    <p>As a subscriber, you agree to:</p>

                                    <ul>
                                        <li>Maintain the confidentiality of your account credentials and be solely responsible for all activities that occur under your account.</li>
                                        <li>Immediately notify Picco Trading of any unauthorized use of your account or any other breach of security.</li>
                                        <li>Provide accurate, complete, and updated information during the registration process and throughout your subscription period.</li>
                                        <li>Regularly monitor your account for any unauthorized transactions or suspicious activity.</li>
                                    </ul>



                                    <h3>7. Intellectual Property Rights</h3>
                                    <p>All content, materials, and services provided by Picco Trading, including but not limited to trade alerts, automated bots, educational content, software, graphics, logos, and trademarks, are protected by copyright, trademark, and other intellectual property laws. Picco Trading retains all rights, title, and interest in and to such content, materials, and services. You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the services for personal, non-commercial purposes. You are prohibited from copying, modifying, distributing, or creating derivative works from any content provided by Picco Trading without our express written consent.</p>

                                    <h2>8. Disclaimers and Limitation of Liability</h2>
                                    <ul>
                                        <li><h3>No Financial, Investment, or Trading Advice:</h3>Picco Trading provides its services, including but not limited to trade alerts, automated trading bots, market analysis, and educational content, for informational and educational purposes only. The content available through our Site and services does not constitute financial, investment, legal, or trading advice. You acknowledge that Picco Trading is not acting as a financial advisor or broker and that the use of our services does not establish a fiduciary relationship. All decisions regarding investments, trades, or other financial activities are solely your responsibility, and you should consult with qualified professionals before making any trading or investment decisions.</li>
                                        <li><h3>No Guarantee of Results or Performance:</h3>Picco Trading makes no representations, warranties, or guarantees of any kind, express or implied, regarding the accuracy, completeness, reliability, or suitability of the services provided. Past performance of any trading strategy, bot, alert, or educational content offered through Picco Trading does not guarantee future results. The use of our services is at your own risk, and you acknowledge that trading and investing in financial markets can result in substantial losses, including the loss of your entire investment.</li>
                                        <li><h3>Services Provided "As-Is" and "As-Available":</h3>All services, content, and materials provided by Picco Trading are offered on an "as-is" and "as-available" basis, without any warranties or representations, express or implied. Picco Trading disclaims all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, accuracy, or reliability. We do not warrant that the services will be uninterrupted, error-free, secure, or free from viruses or other harmful components.</li>
                                        <li><h3>No Liability for Third-Party Content:</h3>Our Site may contain links to third-party websites, resources, or services, including data feeds, news, and other content provided by third parties. Picco Trading does not control, endorse, or assume any responsibility for the accuracy, legality, or content of such third-party websites or services. Your use of third-party content is at your own risk, and Picco Trading is not liable for any damages or losses arising from or related to your use of third-party content.</li>
                                        <li><h3>Limitation of Liability:</h3>To the fullest extent permitted by law, Picco Trading, its affiliates, employees, agents, licensors, service providers, and any other party involved in creating, producing, or delivering the services shall not be liable for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to loss of profits, revenue, data, goodwill, or other intangible losses, arising from or relating to:
                                        <ul>
                                        <li>Your use of or inability to use the services.</li>
                                        <li>Any errors, mistakes, or inaccuracies in the services or content provided.</li>
                                        <li>Unauthorized access to or alteration of your transmissions or data.</li>
                                        <li>Any bugs, viruses, trojans, or other harmful components transmitted to or through our services by any third party.</li>
                                        <li>Any interruption, suspension, or termination of our services, whether in whole or in part.</li>
                                        <li>Any loss or damage arising out of your reliance on any content, information, or materials provided by Picco Trading or its affiliates.</li>
                                        </ul>
                                        </li>

                                        <li><h3>Exclusion of Liability for High-Risk Trading and Investment Activities:</h3>You acknowledge and accept that trading and investing in financial markets, including but not limited to stocks, options, futures, small cap, large cap, forex, and other instruments, involve substantial risk. Picco Trading is not liable for any financial losses or damages you may incur, including but not limited to market losses, trading errors, failed execution, or any other consequences of your trading or investment activities conducted using our services.</li>
                                        <li><h3>Disclaimer of Liability for Automated Trading Tools:</h3>Picco Trading provides access to automated trading bots and tools designed to execute trades based on pre-programmed algorithms. You understand and agree that:
                                        <ul>
                                        <li>These bots and tools are provided for your convenience and do not guarantee profits or prevent losses.</li>
                                        <li>Picco Trading is not responsible for any financial losses or damages arising from the use, misuse, malfunction, or failure of the automated trading bots, including losses due to system failures, connectivity issues, algorithmic errors, or market conditions.</li>
                                        <li>You assume full responsibility for monitoring your trading account and making any necessary adjustments or interventions.</li>
                                        </ul>
                                        </li>

                                        <li><h3>Maximum Liability Cap</h3>In no event shall Picco Trading's total liability to you for all claims arising from or related to this Agreement, whether in contract, tort, or any other legal theory, exceed the total amount of fees paid by you to Picco Trading in the 12 months immediately preceding the event giving rise to the liability. This limitation of liability is cumulative and not per incident, and applies even if Picco Trading has been advised of the possibility of such damages.</li>
                                        <li><h3>Exclusions and Limitations</h3>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you. However, in such jurisdictions, Picco Trading’s liability will be limited to the fullest extent permitted by law.</li>
                                    </ul>
                        
                                    <h3>9. Indemnification</h3>
                                    <p>You agree to indemnify, defend, and hold harmless Picco Trading, its affiliates, officers, directors, employees, agents, licensors, and service providers from and against any claims, losses, damages, liabilities, costs, and expenses (including reasonable attorney's fees) arising from your use of our services, violation of this Agreement, or infringement of any rights of another party.</p>

                                    <h3>10. Modifications to Services</h3>
                                    <p>Picco Trading reserves the right to modify, suspend, or discontinue any part of the services at any time, with or without notice. We may also update this Agreement from time to time to reflect changes in our practices or legal requirements. Your continued use of the services following any changes constitutes your acceptance of the updated terms.</p>                                    
                                    
                                    <h3>11. Privacy and Data Protection</h3>
                                    <p>Your use of the services is also governed by our Privacy Policy, which is incorporated into this Agreement by reference. By using our services, you agree to the collection, use, and sharing of your personal information as described in our Privacy Policy.
                                    </p>
                                    
                                    <h3>12. Governing Law and Dispute Resolution</h3>
                                    <p>This Agreement, and any disputes arising out of or related to this Agreement or your use of the services, shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of laws principles. Any disputes shall be resolved exclusively in the courts located in [Your Jurisdiction]. You consent to the jurisdiction and venue of such courts.</p>
                                    
                                    <h3>13. Entire Agreement</h3>
                                    <p>This Agreement, together with our Privacy Policy and any other terms or documents referenced herein, constitutes the entire agreement between you and Picco Trading concerning your use of the services and supersedes any prior or contemporaneous agreements, communications, or understandings, whether oral or written.</p>

                                    <h3>14. Severability</h3>
                                    <p>If any provision of this Agreement is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect.</p>

                                    <h3>15. No Waiver</h3>
                                    <p>No waiver of any term or condition in this Agreement shall be deemed a further or continuing waiver of such term or any other term, and Picco Trading's failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision.</p>

                                    <h3>16. Contact Information</h3>
                                    <p>If you have any questions, concerns, or requests regarding this Agreement or our services, please contact us at:</p>

                                    <p><strong>Email:</strong> <a href="mailto:info@piccotrading.com">info@piccotrading.com</a></p>

                                    <p>By subscribing to Picco Trading's services, you acknowledge that you have read, understood, and agree to be bound by this Subscriber Agreement.</p>


                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LegalDisclaimer
