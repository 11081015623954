import React from 'react';
import { Link } from 'react-router-dom';
import './../home/style.css';

const features = [
  { title: 'Exclusive Market Insights', description: 'Stay ahead of the curve with our comprehensive analyses of futures, options, and equity markets.', icon: '🖌️', image: "/trade.png" },
  { title: 'Customized Experience', description: 'Tailor your feed to what matters most to you - from small cap wonders to large cap leaders.', icon: '💡', image: "/market.png" },
  { title: 'Continuous Learning', description: 'Benefit from our educational approach to trading, enhancing your skills and knowledge.', icon: '🕒', image: "/educational.png" },
  { title: 'Community and Support', description: 'Join a community of traders and gain access to our dedicated support team.', icon: '📈', image: "/data-driven.png" },
];

const FeatureCard = ({ title, description, icon, image }) => (
  <div className="card">
    <img src={image} className="icon" alt="icons" />
    <h3 className="cardTitle">{title}</h3>
    <p className="cardDescription">{description}</p>
  </div>
);

const FeaturesSection = () => (
  <div className="cards_custom cards_custom_register">
    <div className="container">
      <h2 className="heading">Why Sign Up?</h2>
      <div className="cardContainer">
        {features.map((feature, index) => (
          <FeatureCard key={index} title={feature.title} description={feature.description} icon={feature.icon} image={feature.image} />
        ))}
      </div>
    </div>
  </div>
);

const styles = {
  container: {
    backgroundColor: '#fff', // Replace with actual background color
    textAlign: 'center',
    padding: '50px 20px'
  },
  heading: {
    margin: '0 0 20px',
    fontSize: '30px',
    fontWeight: 'bold',
    color: "#0be096"
  },
  cardContainer: {
    width: "80%",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: "0 auto"
  },
  card: {
    padding: '40px',
    margin: '10px',
    flexBasis: 'calc(25% - 20px)', // 25% width for each card, minus margin
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center'
  },
  icon: {
    fontSize: '24px',
    padding: 16,
    backgroundColor: "#dfe7da",
    borderRadius: "50%",
    margin: "0 auto"
    // height:15,
    // width:15
  },
  cardTitle: {
    margin: '35px 0 10px 0',
    fontSize: '18px',
    fontWeight: 'bold',
    color: "#333"
  },
  cardDescription: {
    fontSize: '14px',
    color: '#666'
  }
};

export default FeaturesSection;
