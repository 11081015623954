import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <>
            <Helmet>
                <title>Marketing and Communication Consent Form | Picco</title>
                <meta name="description" content="Privacy policy" />
                <meta property="og:title" content="Privacy policy | Picco" />
                <meta property="og:description" content="Privacy policy" />
            </Helmet>

            <div className="legal">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className="home-sections legal_container">
                            <div className="home-section" style={{ justifyContent: "flex-start" }}>
                                <div className="home-content02">
                                    <div className='page-title mb-4'> Marketing and Communication Consent Form </div>

                                    <span>
                            <strong>Last Updated: September 5, 2024</strong>
                        </span>

                                    <p>At Picco Trading ("we," "us," "our," or "Company"), we value your privacy and are committed to complying with all applicable data protection and privacy laws. To provide you with relevant updates, offers, and information that align with your interests, we require your explicit consent to send you marketing communications. This Marketing and Communication Consent Form ("Consent Form") outlines how we will use your personal data for marketing purposes and your rights concerning such communications.</p>

                                    <h2>1. Consent to Receive Marketing Communications</h2>
                                    <p>By checking the box or clicking "I Agree" or "Accept," you consent to receive marketing communications from Picco Trading via the following channels:</p>

                                    <ul>
                                        <li><strong>Email:</strong> Newsletters, promotional offers, updates, and other marketing materials.</li>
                                        <li><strong>SMS/Text Messages:</strong> Alerts, notifications, and special offers.</li>
                                        <li><strong>Phone Calls:</strong> Personalized offers, invitations, and customer feedback requests.</li>
                                        <li><strong>Postal Mail:</strong> Brochures, catalogs, and other physical marketing materials.</li>
                                    </ul>

                                    
                                    <p>You may choose one or more communication channels or opt out entirely at any time as described in Section 6 of this Consent Form.</p>


                                    <h3>2. Purpose of Marketing Communications</h3>
                                    <p>Picco Trading will use your personal data to send you marketing communications for the following purposes:</p>

                                    <ul>
                                        <li><strong>Promotional Offers:</strong> Receive exclusive discounts, special offers, and promotions tailored to your preferences.</li>
                                        <li><strong>News and Updates:</strong> Stay informed about our latest products, services, features, and trading insights.</li>
                                        <li><strong>Event Invitations:</strong> Invitations to webinars, workshops, community events, and other activities hosted or sponsored by Picco Trading.</li>
                                        <li><strong>Surveys and Feedback:</strong> Requests for feedback on your experience with our services to improve our offerings and enhance customer satisfaction.</li>
                                    </ul>

                                    <h3>3. Personal Data Used for Marketing Communications</h3>
                                    <p>To provide relevant and targeted marketing communications, we may use the following types of personal data:</p>

                                    <ul>
                                        <li><strong>Contact Information:</strong> Your name, email address, phone number, and mailing address.</li>
                                        <li><strong>Account Information:</strong> Details such as your subscription plan, preferences, and engagement history.</li>
                                        <li><strong>Behavioral Data:</strong> Information about your interactions with our Site, including pages visited, services used, and content viewed.</li>
                                        <li><strong>Communication Preferences:</strong> Your chosen communication channels and preferences.</li>
                                    </ul>


                                    <h3>4. Sharing of Your Personal Data for Marketing Purposes</h3>
                                    <p>Picco Trading will not sell, rent, or lease your personal data to third parties for their marketing purposes. We may share your personal data with trusted third-party service providers who perform marketing functions on our behalf, such as email delivery, SMS messaging, data analysis, and marketing campaign management. These providers are contractually obligated to maintain the confidentiality and security of your data and are prohibited from using it for any other purposes</p>

                                    <h3>5. Storage and Security of Your Personal Data</h3>
                                    <p>Picco Trading employs reasonable and industry-standard security measures to protect your personal data from unauthorized access, use, disclosure, alteration, or destruction. We store your data securely for as long as necessary to fulfill the purposes outlined in this Consent Form or as required by law. Once your data is no longer needed, we will securely delete or anonymize it.</p>

                                    <h3>6. Your Rights and Preferences</h3>
                                    <p>You have the following rights concerning your personal data and marketing communications:</p>

                                    <ul>
                                    <li><strong>Opt-Out:</strong> You may withdraw your consent to receive marketing communications from Picco Trading at any time by:
                                        <ul>
                                        <li>Clicking the "Unsubscribe" link at the bottom of any marketing email.</li>
                                        <li>Replying "STOP" to any SMS message received from us.</li>
                                        <li>Contacting us directly at <a href="mailto:info@piccotrading.com">info@piccotrading.com</a> with your request.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Access and Rectification:</strong> You have the right to request access to the personal data we hold about you and to correct any inaccuracies.</li>
                                    <li><strong>Erasure:</strong> You may request the deletion of your personal data, subject to certain exceptions provided by law.</li>
                                    <li><strong>Restriction of Processing:</strong> You have the right to request that we restrict the processing of your personal data for specific purposes, including marketing.</li>
                                    </ul>

                                    <p>To exercise any of these rights, please contact us using the details provided in Section 9.</p>

                                    <h3>7. Withdrawal of Consent</h3>
                                    <p>You have the right to withdraw your consent to receive marketing communications at any time without affecting the legality of processing based on consent before its withdrawal. To withdraw your consent, please follow the opt-out instructions provided in Section 6 or contact us directly at <a href="mailto:info@piccotrading.com">info@piccotrading.com</a>. Upon withdrawal, you will no longer receive marketing communications from Picco Trading but may still receive essential communications related to your account or services.</p>

                                    <h2>8. Changes to This Consent Form</h2>
                                    <p>Picco Trading reserves the right to modify or update this Marketing and Communication Consent Form at any time without prior notice. Any changes will be posted on our Site, and your continued receipt of marketing communications after such changes constitutes your acceptance of the revised terms. We encourage you to review this Consent Form periodically to stay informed of any updates.</p>

                                    <h3>9. Contact Information</h3>
                                    <p>If you have any questions, concerns, or requests regarding this Consent Form or our marketing practices, please contact us at:</p>
                                    <p><strong>Email:</strong> <a href="mailto:info@piccotrading.com">info@piccotrading.com</a></p>
                                    

                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LegalDisclaimer
