import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import './home.css'
import './about.css'

const LegalDisclaimer = (props) => {
    return (
        <>
            <Helmet>
                <title>Electronic Signature Agreement | Picco</title>
                <meta name="description" content="Privacy policy" />
                <meta property="og:title" content="Privacy policy | Picco" />
                <meta property="og:description" content="Privacy policy" />
            </Helmet>

            <div className="legal">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className="home-sections legal_container">
                            <div className="home-section" style={{ justifyContent: "flex-start" }}>
                                <div className="home-content02">
                                    <div className='page-title mb-4'> Legal Disclaimer </div>

                                    <span>
                                        <strong>Last Updated: September 5, 2024</strong>
                                    </span>

                                    <p>By accessing or using the Picco Trading website, you agree to comply with and be bound by the following terms and conditions. Please read this disclaimer carefully before using our services.</p>

                                    <h2>1. No Financial Advice</h2>
                                    <p>All information provided on the Picco Trading website, including but not limited to trade alerts, market news, analysis, webinars, and automated trading services, is provided for informational purposes only and should not be considered as financial, investment, or trading advice. Picco Trading, its affiliates, and representatives are not licensed financial advisors, brokers, or dealers. The content and tools provided are based on data and publicly available information and do not constitute recommendations or endorsements of any specific securities, financial instruments, or strategies. You are solely responsible for conducting your own research, exercising due diligence, and obtaining independent professional advice before making any trading decisions.</p>

                                    <h2>2. Risk of Trading</h2>
                                    <p>Trading in financial markets, including but not limited to stocks, options, futures, small-cap, large-cap, and other financial instruments, involves substantial risk and may result in significant financial loss. The use of leverage and margin can amplify gains and losses, and there is no guarantee that you will achieve your investment goals. Picco Trading does not guarantee any specific results or profits and disclaims any liability for financial losses or damages that may result from the use of its products or services. You acknowledge that you fully understand the risks involved and are solely responsible for any trading decisions you make.</p>

                                    <h2>3. Performance Disclaimer</h2>
                                    <p>Past performance is not indicative of future results. Any performance data, results, or examples provided on the Picco Trading website or related materials are hypothetical, illustrative, and not guaranteed. Actual trading results may vary significantly depending on market conditions, volatility, individual trading strategies, and other external factors. Picco Trading makes no representation that any account will or is likely to achieve profits or losses similar to those shown. Users should be aware that trading can result in the loss of all invested capital.</p>

                                    <h2>4. Automated Trading Bots</h2>
                                    <p>Picco Trading’s automated trading bots are designed to execute trades based on pre-programmed algorithms and data analysis. These bots are provided as tools to facilitate trading but do not guarantee profits and may incur losses due to market conditions, system failures, connectivity issues, or unforeseen events. You acknowledge that automated trading involves substantial risk and that Picco Trading is not liable for any financial losses or damages resulting from the use or failure of its automated trading bots. It is your responsibility to monitor and manage your trading account regularly.</p>

                                    <h2>5. Subscription and Access</h2>
                                    <p>
                                        By subscribing to any Picco Trading services, you agree to pay all applicable fees for your chosen subscription plan. Subscriptions automatically renew at the end of each term unless canceled before the renewal date. You are responsible for managing your subscription and ensuring timely cancellation to avoid additional charges. Picco Trading reserves the right to modify, suspend, or terminate subscriptions or access to any service at its sole discretion. Refunds are not provided for partially used subscription periods, and all fees are non-refundable unless otherwise stated.
                                    </p>

                                    <h2>6. Affiliate Disclaimer</h2>
                                    <p>Picco Trading may participate in affiliate marketing programs and may receive commissions from purchases made through affiliate links on this website. These links are provided for your convenience, and Picco Trading does not endorse or take responsibility for the products or services offered by third-party affiliates. While we strive to recommend only high-quality products, we encourage you to perform your own research and make informed decisions before making any purchase.</p>

                                    <h2>7. Intellectual Property</h2>
                                    <p>All content, materials, trademarks, logos, graphics, images, and software on the Picco Trading website are the exclusive property of Picco Trading or its licensors and are protected by intellectual property laws. You are prohibited from using, reproducing, distributing, modifying, or creating derivative works from any content without the express written permission of Picco Trading. Any unauthorized use may result in legal action.</p>

                                    <h2>8. Copyright and Trademarks</h2>
                                    <p>All materials on this website, including but not limited to text, graphics, images, logos, and software, are protected by copyright laws and are the property of Picco Trading or its content suppliers. You may not copy, reproduce, distribute, display, or create derivative works from any content on this website without the express written consent of Picco Trading. All trademarks, service marks, and trade names are proprietary to Picco Trading. Unauthorized use of any Picco Trading trademarks is strictly prohibited and may result in legal consequences.</p>

                                    <h2>9. Limitation of Liability</h2>
                                    <p>To the fullest extent permitted by law, Picco Trading, its affiliates, employees, agents, licensors, or service providers shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to loss of profits, data, goodwill, or other intangible losses arising from (i) your use or inability to use our services, (ii) unauthorized access to or alteration of your transmissions or data, (iii) statements or conduct of any third party on our services, or (iv) any other matter relating to our services. This limitation applies regardless of the legal theory under which such damages are sought</p>

                                    <h2>10. User Responsibility</h2>
                                    <p>You agree that you are solely responsible for your actions and decisions based on the information, tools, or services provided by Picco Trading. You understand that trading carries inherent risks and that Picco Trading does not warrant or guarantee any specific outcomes. You agree to comply with all applicable laws and regulations in your jurisdiction and to use our services in a lawful manner.</p>

                                    <h2>11. Indemnification</h2>
                                    <p>You agree to indemnify, defend, and hold harmless Picco Trading, its affiliates, officers, directors, employees, agents, licensors, and service providers from and against any and all claims, losses, damages, liabilities, costs, and expenses (including reasonable attorney's fees) arising out of or related to your use of our services, violation of these terms, or infringement of any rights of another party.
                                    </p>

                                    <h2>12. Third-Party Content and Links</h2>
                                    <p>Picco Trading may provide links to third-party websites, resources, or services for your convenience. Picco Trading does not endorse, control, or assume any responsibility for the content, privacy policies, or practices of any third-party websites. Accessing any third-party content through our website is at your own risk, and you are solely responsible for reviewing and understanding the terms and policies of any third-party sites.</p>

                                    <h2>13. Changes to This Disclaimer</h2>
                                    <p>Picco Trading reserves the right to modify, amend, or update this Legal Disclaimer at any time without prior notice. Any changes will be posted on this page, and your continued use of the website and our services after any changes constitutes acceptance of the updated terms. We encourage you to review this disclaimer periodically to stay informed of any changes.</p>

                                    <h2>14. Governing Law</h2>
                                    <p>This Legal Disclaimer and any disputes arising from your use of the Picco Trading website shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. By using this website, you consent to the exclusive jurisdiction of the courts located in [Your Jurisdiction] for any legal action or proceeding.</p>

                                    <h2>15. Contact Information</h2>
                                    <p>If you have any questions or concerns about this Legal Disclaimer or our services, please contact us at </p>

                                    <p><strong>Email:</strong> <a href="mailto:info@piccotrading.com">info@piccotrading.com</a></p>

                                    <p>By using the Picco Trading website and its services, you acknowledge that you have read, understood, and agree to be bound by this Legal Disclaimer.
                                    </p>


                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LegalDisclaimer
